
















import {Component, Prop, Vue} from "vue-property-decorator";

@Component
export default class ResponseDisplay extends Vue {
  @Prop() readonly response!: Response;
}
