




































































import {Component, Vue} from 'vue-property-decorator';
import ResponseDisplay from "@/components/ResponseDisplay.vue";

@Component({
  components: {ResponseDisplay}
})
export default class Home extends Vue {
  cols = 6;
  offset = 3;

  httpMethods = ['GET', 'POST']

  readonly cookieName = 'BYODLauncherDevLogin';
  readonly requestProperties = [
    'userId',
    'firstName',
    'lastName',
    'targetUri',
    'httpMethod',
  ];

  userId = '';
  firstName = '';
  lastName = '';
  targetUri = 'https://localhost:'
  httpMethod = this.httpMethods[1];

  headers: { [key: string]: string } = {
    'Content-Type': 'application/javascript',
    'Authorization': this.userId,
  };

  requestPending = false;
  response: Response | null = null;
  error: string | null = null;

  mounted() {
    this.loadLocalData();
  }

  sendRequest() {
    this.saveData();

    this.requestPending = true;

    const controller = new AbortController();
    const id = setTimeout(() => controller.abort(), 5000);

    fetch(this.targetUri, {
      method: this.httpMethod,
      headers: this.getHeaders(),
      signal: controller.signal,
    })
        .then(response => {

          if (response.redirected) {
            window.location.href = response.url;
          }

          this.error = null;
          this.response = response;
        })
        .catch(reason => {
          console.log(reason);
          this.response = null;
          this.error = reason;
        })
        .finally(() => {
          clearTimeout(id);
          this.requestPending = false;
        });
  }

  saveData() {
    const jsonData = {
      userId: this.userId,
      firstName: this.firstName,
      lastName: this.lastName,
      targetUri: this.targetUri,
      httpMethod: this.httpMethod,
    };
    const value = JSON.stringify(jsonData);
    const date = new Date();
    date.setTime(date.getTime() + (14 * 24 * 60 * 60 * 1000));
    const expires = "; expires=" + date.toUTCString();

    document.cookie = this.cookieName + "=" + (value || "") + expires + "; path=/";
  }

  loadLocalData() {
    const cookieName = this.cookieName + "=";
    const cookies = document.cookie.split(';');
    for (let i = 0; i < cookies.length; i++) {
      let cookie = cookies[i];
      while (cookie.charAt(0) == ' ') {
        cookie = cookie.substring(1, cookie.length);
      }
      if (cookie.indexOf(cookieName) == 0) {
        const stringData = cookie.substring(cookieName.length, cookie.length);
        const jsonData = JSON.parse(stringData);
        this.userId = jsonData.userId;
        this.firstName = jsonData.firstName;
        this.lastName = jsonData.lastName;
        this.targetUri = jsonData.targetUri;
        this.httpMethod = jsonData.httpMethod;
      }
    }
  }

  private getHeaders() {
    const headers = this.headers;
    headers['X-First'] = this.firstName;
    headers['X-Last'] = this.lastName;
    headers['X-UserId'] = this.userId;
    return headers;
  }
}
